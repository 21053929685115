import { MaskDirectiveOptions } from '../interfaces'
import { UndefinedMask } from '../errors'
import { loadPresets } from '../presets'

export function buildConfig ({ arg: mask }: any): MaskDirectiveOptions {
  if (!mask) throw new UndefinedMask(mask)
  if (typeof mask === 'object' && 'loaded' in mask) return mask

  const config: MaskDirectiveOptions = typeof mask === 'string' || Array.isArray(mask)
    ? { mask }
    : { ...mask }

  if (!config.kind) config.kind = 'external'
  if (!config.mask?.length) throw new UndefinedMask(mask)

  return loadPresets(config)
}
export default buildConfig
