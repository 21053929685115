import { MaskToken, MaskType } from './interfaces'

export class UndefinedMask extends Error {
  constructor (mask: MaskType) {
    super(`Undefined mask for v-mask directive: "${mask}"`)
  }
}

export class InvalidFacade extends Error {
  constructor (facade: MaskType) {
    super(`Invalid facade type at v-mask: "${facade}"`)
  }
}

export class InvalidToken extends Error {
  constructor (token: MaskToken) {
    super(`Invalid token for v-mask directive: "${token}"`)
  }
}
