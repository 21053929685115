import { MaskTokens } from '../interfaces'
import { loadTransformer } from './transformer'
import { InvalidToken } from '../errors'

export const tokens: MaskTokens = loadTokens({
  A: { pattern: /[a-zA-Z]/, transform: 'toUpperCase' },
  a: { pattern: /[a-zA-Z]/, transform: 'toLowerCase' },
  0: { pattern: /[0-9]/, optional: true },
  9: { pattern: /[0-9]/, repeated: true },
})

export function loadTokens (tokens: MaskTokens) {
  Object.entries(tokens).forEach(([token, config]) => {
    if (!config || typeof config !== 'object') throw new InvalidToken(config)
    if ('escape' in config) return

    if (!config?.pattern) throw new InvalidToken(config)
    config.transform = loadTransformer(config)
  })

  return tokens
}

export default tokens
