import { MaskDirectiveOptions, MaskType } from '../interfaces'

import { loadMask } from './masks'
import { loadFacade } from './facade'
import { loadTokens } from './token'

export function loadPresets (config: MaskDirectiveOptions): MaskDirectiveOptions {
  config = loadMask(config)
  config.mask = loadFacade(config.mask as MaskType)
  config.tokens = loadTokens({ ...config.tokens })

  Object.defineProperty(config, 'loaded', { value: true })
  return config
}

export default loadPresets
