import { TransformerName, TransformerConfig, TransformerRunner, MaskToken } from '../interfaces'

export const transformers: Record<TransformerName, TransformerConfig | TransformerRunner> = {
  toUpperCase: v => v.toLocaleUpperCase(),
  toLowerCase: v => v.toLocaleLowerCase(),
}

export function loadTransformer (token: MaskToken) {
  const { transform } = token
  if (typeof transform !== 'string') return transform

  const transformer = transformers[transform]
  if (!transformer) throw new Error(`Unknown transformer "${transform}" on v-mask`)

  return transformer
}

export default transformers
