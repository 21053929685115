// Styles
import './GTextField.sass'

// Extensions
import VTextField from '../../../components/VTextField/VTextField'
import GLabel from '../GLabel'

// Utilities
import mixins from '../../../util/mixins'

// Types
const baseMixins = mixins<InstanceType<typeof VTextField>>(VTextField)

export default baseMixins.extend({
  name: 'g-text-field',

  props: {
    outlined: { default: true },
    persistentPlaceholder: { default: false },
    required: Boolean,
  },

  computed: {
    classes (): object {
      const { lazyValue, value, isDisabled } = this

      const hasContent = !isDisabled ? lazyValue?.length || value : false

      return {
        'g-text-field': true,
        'g-text-field--solo': this.isSolo,
        'g-text-field--outlined': this.outlined,
        'g-text-field--has-content': hasContent,
        ...VTextField.options.computed.classes.call(this),
      }
    },
    internalValue: {
      get (): any {
        return this.lazyValue
      },
      set (val: any) {
        const { unmaskedValued } = this
        this.lazyValue = unmaskedValued ?? val
        this.$emit('input', this.lazyValue)
      },
    },
  },

  methods: {
    setLabelWidth () {
      if (!this.outlined) return

      this.labelWidth = this.$refs.label
        ? Math.min(
          this.$refs.label.scrollWidth * 0.75,
          (this.$el as HTMLElement).offsetWidth - 24
        )
        : 0
    },
    genLabel () {
      if (!this.showLabel) return null

      const data = {
        props: {
          absolute: true,
          color: this.validationState,
          dark: this.dark,
          disabled: this.isDisabled,
          focused: !this.isSingle && (this.isFocused || !!this.validationState),
          for: this.computedId,
          left: this.labelPosition.left,
          light: this.light,
          right: this.labelPosition.right,
          value: this.labelValue,
          required: this.required,
        },
      }

      return this.$createElement(GLabel, data, this.$slots.label || this.label)
    },
  },
})
