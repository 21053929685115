import { MaskDirectiveOptions } from '../interfaces'

const masks: Record<string, MaskDirectiveOptions> = {
  integer: {
    mask: '9.99#',
    kind: 'external',
    reversed: true,
  },
}

export function loadMask (opts: MaskDirectiveOptions) {
  const { mask } = opts
  if (!mask || typeof mask !== 'string') return opts

  const found = masks[mask]
  if (!found) return opts

  return Object.assign({ ...found }, opts, { mask: found.mask })
}
