// Styles
import './GInput.sass'

// Extensions
import VInput from '../../../components/VInput/VInput'

// Directives
import { buildMask, vMaska as mask } from '../../directives/mask'

// Utilities
import { convertToUnit } from '../../../util/helpers'
import mixins from '../../../util/mixins'

// Types
const baseMixins = mixins<InstanceType<typeof VInput>>(VInput)

export default baseMixins.extend({
  name: 'g-input',

  directives: {
    mask,
  },

  props: {
    gMask: {
      type: [String, Object],
    },
  },

  data: () => ({
    unmaskedValued: null,
  }),

  computed: {
    classes (): object {
      const { isDisabled } = this

      return {
        'g-input': true,
        'g-input--is-disabled': isDisabled,
        ...VInput.options.computed.classes.call(this),
      }
    },
    maskOptions () {
      const { gMask: mask } = this
      if (!mask) return undefined

      const options = buildMask({ arg: mask })
      options.onMaska = detail => {
        this.unmaskedValued = options.kind === 'external' ? detail.unmasked : null
        this.$emit('mask:input', detail)
      }

      return options
    },
    internalValue: {
      get (): any {
        return this.lazyValue
      },
      set (val: any) {
        const { unmaskedValued } = this
        this.lazyValue = unmaskedValued ?? val
        this.$emit(this.$_modelEvent, this.lazyValue)
      },
    },
  },

  methods: {
    genInputSlot () {
      let directives
      const { maskOptions } = this
      if (maskOptions) directives = [{ name: 'mask', arg: maskOptions }]

      return this.$createElement('div', this.setBackgroundColor(this.backgroundColor, {
        staticClass: 'v-input__slot',
        style: { height: convertToUnit(this.height) },
        directives,
        on: {
          click: this.onClick,
          mousedown: this.onMouseDown,
          mouseup: this.onMouseUp,
        },
        ref: 'input-slot',
      }), [this.genDefaultSlot()])
    },
  },
})
