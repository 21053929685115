import { MaskType } from '../interfaces'
import { InvalidFacade } from '../errors'

export const facades: Record<string, MaskType | Array<MaskType>> = {
  'credit-card': '#### - #### - #### - ####',
}

export function loadFacade (facade: MaskType): MaskType {
  if (typeof facade !== 'string' && !Array.isArray(facade)) throw new InvalidFacade(facade)

  const names = typeof facade === 'string' ? [facade] : facade
  return names.map(name => facades[name] || name).flat() as MaskType
}

export default facades
